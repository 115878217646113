import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

import App from "./App";
import { Environment, getEnvironment } from "utils/environments";
// import reportWebVitals from "reportWebVitals";

import theme from "theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const environment = getEnvironment();
if (environment !== Environment.LOCAL) {
  Sentry.init({
    dsn: "https://efd20a8c12e19f862855f3a9767ba9b3@o4507498102063104.ingest.us.sentry.io/4507526612647936",
    integrations: [],
    environment,
  });
  Sentry.setTag("origin", window.location.origin);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
