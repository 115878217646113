export enum Environment {
  LOCAL = "local",
  DEVELOPMENT = "development",
  PRODUCTION = "production",
}

// There is probably a better way to do it using env variables etc. But this is fast and clean, good enough for now.
export const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    return Environment.LOCAL;
  } else if (hostname.includes("aigency-agent--")) {
    return Environment.DEVELOPMENT;
  } else {
    return Environment.PRODUCTION;
  }
};
