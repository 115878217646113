import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
    fontWeightMedium: 400,
    body1: {
      letterSpacing: "0.15px",
    },
  },
});

export default theme;
