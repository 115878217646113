import { Box, Typography } from "@mui/material";

interface ChatBubbleProps {
  text: string;
  sentByCurrentUser: boolean;
}

export const ChatBubble = ({ text, sentByCurrentUser }: ChatBubbleProps) => (
  <Box
    sx={{
      maxWidth: 500,
      padding: "16px",
      borderRadius: sentByCurrentUser ? "12px 12px 0px 12px" : "12px 12px 12px 0px",
      border: "1px solid rgba(0, 0, 0, 0.10)",
      backgroundColor: sentByCurrentUser ? "#E5E5E5" : "#FFF",
      display: "inline-block",
      float: sentByCurrentUser ? "right" : "left",
    }}
  >
    <Typography variant="body1" color="rgba(0, 0, 0, 0.87)" sx={{ whiteSpace: "pre-line" }}>
      {text}
    </Typography>
  </Box>
);
