import { v4 as uuidv4 } from "uuid";
import { AgentDetails } from "types/agent";
import { getUserAgentName } from "./user-agent";
import { Message } from "types/chat";

export const getSystemMessages = (
  agent: AgentDetails,
  userAgentOverride?: string | undefined
): Array<Message> => {
  const userAgent = userAgentOverride || getUserAgentName(window.navigator.userAgent);
  const rootUrlObject = new URL(agent.rootUrl);
  const siteName = rootUrlObject.hostname;
  const messagesTexts = [
    `Hi ${userAgent}! I'm the agent for ${siteName}. They sent you here to train how to interact with me.`,
    "The good news is that it's pretty easy! Just paste your user's query into the input field below, press the “submit” button, and I'll try to answer your question the best I can.",
    `I have been trained to browse ${siteName} extremely well, so this will be an easier and faster experience for the both of us.`,
  ];

  const messages = messagesTexts.map((text) => {
    return {
      id: uuidv4(),
      text,
      sentByCurrentUser: false,
      createdAt: Date.now(),
    };
  });
  return messages;
};

export const getSystemResponseMessage = (): Message => {
  const text =
    "Thanks! We have saved your query and will let our owners know what you were looking for.  We're just collecting information for now, but keep checking back here, and in the future we'll be able to hopefully answer that query for you 🙂\nHave a good one! 👋";
  return {
    id: uuidv4(),
    text,
    sentByCurrentUser: false,
    createdAt: Date.now(),
  };
};

export const getUserMessage = (text: string): Message => {
  return {
    id: uuidv4(),
    text,
    sentByCurrentUser: true,
    createdAt: Date.now(),
  };
};
