import { db } from "firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { AgentDetails } from "types/agent";
import { logError } from "./logging";

export async function getAgentDetails(agentUrl: string) {
  const agentsCollection = collection(db, "agents");

  try {
    const [querySnapshot1, querySnapshot2] = await Promise.all([
      getDocs(query(agentsCollection, where("testUrl", "==", agentUrl))),
      getDocs(query(agentsCollection, where("liveUrl", "==", agentUrl))),
    ]);

    if (!querySnapshot1.empty) {
      const agentDoc = querySnapshot1.docs[0];
      return agentDoc.data() as AgentDetails;
    } else if (!querySnapshot2.empty) {
      const agentDoc = querySnapshot2.docs[0];
      return agentDoc.data() as AgentDetails;
    } else {
      logError("No matching agent found", "");
    }
  } catch (error: any) {
    logError("Error finding agent details", error.message);
  }
}
