import React, { useState, useEffect, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

interface CustomAvatarProps {
  src: string | undefined;
  alt: string;
  variant?: "circular" | "rounded" | "square";
  maxHeight: number;
}

const CustomAvatar: React.FC<CustomAvatarProps> = ({ src, alt, variant, maxHeight }) => {
  const [loaded, setLoaded] = useState(false);
  const [avatarHeight, setAvatarHeight] = useState<number | null>(null);
  const [aspectRatio, setAspectRatio] = useState<number | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current) {
      const handleLoad = () => {
        const img = imgRef.current!;
        const naturalHeight = img.naturalHeight;
        const height = Math.min(naturalHeight, maxHeight);
        setAvatarHeight(height);
        setAspectRatio(img.naturalWidth / img.naturalHeight);
        setLoaded(true);
      };
      const img = imgRef.current;
      img.addEventListener("load", handleLoad);
      return () => img.removeEventListener("load", handleLoad);
    }
  }, [src, maxHeight]);

  const computedWidth = aspectRatio && avatarHeight ? avatarHeight * aspectRatio : "auto";

  return (
    <Box position="relative" width="auto" height={avatarHeight || "auto"} display="inline-block">
      {!loaded && <div />}
      {loaded && (
        <Avatar
          src={src}
          alt={alt}
          variant={variant || "circular"}
          sx={{
            height: avatarHeight,
            width: computedWidth,
            objectFit: "contain",
          }}
        />
      )}
      <img ref={imgRef} src={src} alt={alt} style={{ display: "none" }} />
    </Box>
  );
};

export default CustomAvatar;
