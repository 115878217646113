// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { Environment, getEnvironment } from "utils/environments";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBM2b1sIP57mNI_BH1sDgx5Kms1nGafmk8",
  authDomain: "aigency-agent.firebaseapp.com",
  projectId: "aigency-agent",
  storageBucket: "aigency-agent.appspot.com",
  messagingSenderId: "961209077929",
  appId: "1:961209077929:web:8fc486afeac69aa06ed9b8",
  measurementId: "G-VMHXGTPZV9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app);

if (getEnvironment() === Environment.LOCAL) {
  connectFirestoreEmulator(db, "localhost", 8083);
}

export default app;
