import { ReloadOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { INPUT_HEIGHT } from "pages/constants";

interface ChatResetProps {
  handleReset: () => void;
}

export default function ChatReset({ handleReset }: ChatResetProps) {
  return (
    <Box
      width="100%"
      height={INPUT_HEIGHT}
      sx={{
        borderRadius: 28,
        border: "1px solid rgba(0, 0, 0, 0.10)",
        background: "#F0F0F0",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <IconButton
        onClick={handleReset}
        sx={{ marginRight: "5px", "&:hover": { bgcolor: "transparent" } }}
      >
        <ReloadOutlined style={{ color: "#2196F3", fontSize: "20px" }} />
      </IconButton>
      <ButtonBase onClick={handleReset}>
        <Typography
          variant="body1"
          sx={{
            color: "#2196F3",
            textDecorationLine: "underline",
          }}
        >
          Reset session for a new query
        </Typography>
      </ButtonBase>
    </Box>
  );
}
