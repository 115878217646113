import { useRef, useState } from "react";

// material-ui
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

// assets
import SendIcon from "@mui/icons-material/Send";
import InputAdornment from "@mui/material/InputAdornment";
import { INPUT_HEIGHT } from "pages/constants";

interface ChatMessageSendProps {
  handleSendMessage: (text: string) => void;
}

// ==============================|| CHAT - MESSAGE SEND ||============================== //

export default function ChatMessageSend({ handleSendMessage }: ChatMessageSendProps) {
  const [input, setInput] = useState("");
  const textInput = useRef(null);

  const handleOnSend = () => {
    handleSendMessage(input);
    setInput("");
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (event?.key !== "Enter") {
      return;
    }
    handleOnSend();
  };

  return (
    <Box display="flex">
      <TextField
        inputRef={textInput}
        fullWidth
        multiline
        rows={1}
        placeholder="Ask me anything..."
        value={input}
        onChange={(e) =>
          setInput(e.target.value.length <= 1 ? e.target.value.trim() : e.target.value)
        }
        onKeyDown={handleEnter}
        variant="outlined"
        size="medium"
        sx={{
          "& .MuiOutlinedInput-root": {
            height: INPUT_HEIGHT,
            borderRadius: 28,
            border: "1px solid rgba(0, 0, 0, 0.10)",
            background: "#FFF",
            pl: 3,
            "& fieldset": {
              display: "none",
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={!input.trim()} onClick={handleOnSend} size="medium">
                <SendIcon sx={{ color: "black", fillOpacity: !input.trim() ? 0.35 : 0.9 }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
