import Stack from "@mui/material/Stack";
import CustomAvatar from "components/avatar/CustomAvatar";
import UserAgentOverrideDropdown from "components/user-agent/UserAgentOverrideDropdown";
import { HEADER_HEIGHT } from "pages/constants";

interface ChatHeaderProps {
  userAgentOverride: string;
  setUserAgentOverride: (userAgentOverride: string) => void;
  companyLogo: string | undefined;
  headerBackground: string | undefined;
}

const ChatHeader = ({
  userAgentOverride,
  setUserAgentOverride,
  companyLogo,
  headerBackground,
}: ChatHeaderProps) => {
  return (
    <Stack
      maxWidth="960"
      padding="0px 36px 0px 48px"
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
      sx={{
        height: HEADER_HEIGHT,
        borderRadius: 100,
        background: headerBackground || "#000000",
        backdropFilter: "blur(50px)",
      }}
    >
      <CustomAvatar alt="company logo" variant="square" src={companyLogo} maxHeight={32} />
      <UserAgentOverrideDropdown
        userAgentOverride={userAgentOverride}
        setUserAgentOverride={setUserAgentOverride}
      />
    </Stack>
  );
};

export default ChatHeader;
