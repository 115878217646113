import axios from "axios";
import { AgentDetails } from "types/agent";
import { Message } from "types/chat";
import { logError, logInfo } from "utils/logging";

export function sendMessageEvent(
  message: Message,
  agent: AgentDetails | undefined,
  agentUrl: string,
  userAgentOverride: string
) {
  const eventData = {
    type: "message",
    rootUrl: agent?.rootUrl,
    agentUrl,
    userAgent: userAgentOverride || window.navigator.userAgent,
    text: message.text,
  };
  sendEvent(eventData, agent?.apiKey);
}

export function sendVisitEvent(agent: AgentDetails, agentUrl: string, userAgentOverride: string) {
  const eventData = {
    type: "visit",
    rootUrl: agent.rootUrl,
    agentUrl,
    userAgent: userAgentOverride || window.navigator.userAgent,
  };
  sendEvent(eventData, agent.apiKey);
}

async function sendEvent(eventData: any, apiKey: string | undefined) {
  try {
    // "http://127.0.0.1:5002/recordAgentEvent"
    await axios.post("https://portal.aigency.ai/recordAgentEvent", eventData, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey || "",
      },
    });
    logInfo("Event sent", { eventType: eventData.type });
  } catch (error: any) {
    logError("Error sending event", error.message, error.response?.data);
  }
}
