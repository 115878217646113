import { forwardRef } from "react";
import { Message } from "types/chat";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { ChatBubble } from "./ChatBubble";
import { MESSAGES_HEIGHT_DECREASE } from "pages/constants";

interface ChatMessagesProps {
  messages: Message[];
  companyAvatar: string | undefined;
}

const ChatMessages = forwardRef<HTMLDivElement, ChatMessagesProps>(
  ({ messages, companyAvatar }, ref) => {
    const getMarginBottom = (currentMessage: Message, index: number) => {
      if (index === messages.length - 1) {
        // last message
        return "24px";
      }
      const nextMessage = messages[index + 1];
      return currentMessage.sentByCurrentUser === nextMessage.sentByCurrentUser ? "2px" : "24px";
    };

    const shouldShowAvatar = (index: number) => {
      // Avatar should be shown only for the last message in a system messages batch.
      return index === messages.length - 1 || messages[index + 1].sentByCurrentUser;
    };

    return (
      <Box
        sx={{
          height: `calc(100vh - ${MESSAGES_HEIGHT_DECREASE}px)`,
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            // Chrome, Safari
            display: "none",
          },
          msOverflowStyle: "none", // IE and Edge
          scrollbarWidth: "none", // Firefox
        }}
      >
        <Grid container>
          {messages.map((message, index) => (
            <Grid item xs={12} key={index} sx={{ marginBottom: getMarginBottom(message, index) }}>
              {message.sentByCurrentUser ? (
                <Grid container justifyContent="flex-end">
                  <Grid item xs={10} sm={7}>
                    <ChatBubble text={message.text} sentByCurrentUser />
                  </Grid>
                </Grid>
              ) : (
                <Grid container alignItems="flex-end">
                  {shouldShowAvatar(index) && (
                    <Grid item>
                      <Avatar
                        alt="company avatar"
                        src={companyAvatar}
                        sx={{
                          width: "40px",
                          marginRight: "10px",
                          border: "1px solid rgba(0, 0, 0, 0.10)",
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={10}
                    sm={7}
                    sx={{
                      marginLeft: shouldShowAvatar(index) ? 0 : "50px",
                    }}
                  >
                    <ChatBubble text={message.text} sentByCurrentUser={false} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
          {/* For auto scrolling on new messages */}
          <Grid item ref={ref} />
        </Grid>
      </Box>
    );
  }
);

export default ChatMessages;
