export const knownUserAgents = [
  "AmazonBot",
  "anthropic-ai",
  "Applebot",
  "ChatGPT-Aigency",
  "ChatGPT-User",
  "Claude-Web",
  "Google-Extended",
  "GPTBot",
  "PerplexityBot",
];

export const knownUserAgentsForMenu = ["Default", ...knownUserAgents];

export const getUserAgentName = (userAgentFullString: string) => {
  for (const knownUserAgent of knownUserAgents) {
    if (userAgentFullString.includes(knownUserAgent)) {
      return knownUserAgent;
    }
  }
  return "User Agent"; // Or something else?
};
